import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import moment from "moment";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfContent from "@/vue/domain/content/df-content";

@Component({
  filters: {
    dateFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_PATTER) : "";
    },
  },
})
export default class DfPushCardComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: () => ({}) })
  push!: DfContent;
  @Prop({ type: Number, default: null })
  position!: number;
  @Prop({ type: Boolean, default: true })
  swipeEnabled!: boolean;

  private deletable = false;

  get pushTitle(): string {
    return Utils.getPropertyValue(this.push, Utils.PROPERTY_TITLE, "STRING");
  }

  get pushDate(): string {
    return Utils.getPropertyValue(this.push, Utils.PROPERTY_DATE, "DATE");
  }

  get pushText(): string {
    return Utils.getPropertyValue(this.push, Utils.PROPERTY_TEXT, "TEXT");
  }

  get storedPushesList(): Array<any> {
    return this.$store.getters.storedPushesList;
  }

  set storedPushesList(storedPushesList: Array<any>) {
    this.$store.dispatch("setStoredPushesList", storedPushesList);

    if (storedPushesList.length > 0) {
      Utils.localStorageSetItem("df-pushes-list", JSON.stringify(this.storedPushesList));
    } else {
      Utils.localStorageRemoveItem("df-pushes-list");
    }
  }

  get isNew(): boolean {
    return this.storedPushesList.findIndex((storedPush: any) => storedPush.alias === this.push.alias) === -1;
  }

  private handleSwipe(eventName: string, event: any) {
    if (!this.swipeEnabled) {
      this.deletable = eventName === "left";
    }
  }

  private removePush() {
    if (this.isNew) {
      this.storedPushesList = [...this.storedPushesList, { alias: this.push.alias, deleted: true }];
    } else {
      this.storedPushesList = this.storedPushesList.map((storedPush: any) => {
        if (storedPush.alias === this.push.alias) {
          storedPush.deleted = true;
        }
        return storedPush;
      });
    }
  }
}
